/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@import "@angular/material/prebuilt-themes/indigo-pink.css";

// AG Grid
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

/* angular-calendar */
@import "../node_modules/angular-calendar/css/angular-calendar.css";


.idiving-color {
  background-color: #67AACA !important;
}

.idiving-header-color {
  background-color: #ffffff !important;
}

.idiving-footer-color {
  background-color: #47626F !important;
}

.idiving-color-light {
  color: #f4f3f3;
}

.idiving-content-font{
  font-size:1.2rem;
  font-weight:400;
}
